import React from 'react';
import ImpressumModal from '../ImpressumModal/ImpressumModal';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <ImpressumModal />
      {/* Du kannst hier weitere Inhalte zum Footer hinzufügen, wenn nötig. */}
    </div>
  );
}

export default Footer;
