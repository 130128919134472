
import './App.css';
import Footer from './components/Footer/Footer';
import ImpressumModal from './components/ImpressumModal/ImpressumModal';
import Main from './components/Main/Main';
import Header from './components/Header/Header';

function App() {
  return (
    <div className="App">
    <Header/>
    <Main/>
    <Footer/>
    </div>
  );
}

export default App;
